<template>
    <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
        
        <a-form 
            :form="form" 
            style="padding:0 24px" 
            :label-col="{span:5}" 
            :wrapper-col="{span:12}"
            @submit="handleSubmit"
            >
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <h4>Edit</h4>
            </a-form-item>
            <a-form-item label="Name">
                <a-input placeholder="Please fill in the name of this category"
                v-decorator="['name', { rules: [{ required: true, message: 'Please input name!' }] }]"
                ></a-input>
            </a-form-item> 
            <a-form-item label="Start">
                <a-date-picker
                v-decorator="['start', { }]"
                :disabled-date="disabledDate"
                >
                </a-date-picker>
            </a-form-item>
            <a-form-item label="End">
                <a-date-picker
                v-decorator="['end', { }]"
                :disabled-date="disabledDate"
                >                    
                </a-date-picker>
            </a-form-item>
            <a-form-item label="Description">
                <a-textarea placeholder="Explain the detail/purpose of theis category"
                v-decorator="['description', { rules: [{ required: false, message: 'Please input description!' }] }]"
                >
                </a-textarea>
            </a-form-item>       
            <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                <a-button type="primary" html-type="submit" icon="save" @click="info">Save</a-button>
                <a-space></a-space>
                <a-button type="secondary" icon="close" style="margin-left:10px" @click="backtocategorylist">Cancel</a-button>
            </a-form-item>
        </a-form>

    </a-card>
</template>
<script>
import {addorupdate, getitem} from '@/api/category';
import { message } from 'ant-design-vue';
import moment, { Moment } from 'moment';

const disabledDate = (current) => {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    };


export default ({
    methods: {
        info(){
            message.success('This category has been saved successfully!');
        },
        // function: category-16
        backtocategorylist(){
            this.$router.go(-1);
        },
        // function: category-15
        handleSubmit(e){
            e.preventDefault();
            this.form.validateFields((err,values)=>{
                if (!err){
                    values.id=this.categoryid;
                    console.log(values)
                    addorupdate(values).then(data=>{
                        this.$router.go(-1);
                    });
                }
            });
        }
    },
    data() {
        return {
            form: this.$form.createForm(this,{name: 'category'}),
            productcolumns: [{title:"test 1", dataIndex:'name'}],
            productdata:[{name:'test'}],
            disabledDate,
            moment,
        };
    },
    mounted() {
        var id = this.categoryid;
        if (id==0){
            this.form.setFieldsValue(
                {
                    name:'',
                    description:""
                }
            );
        } else {
            getitem(id).then(data=>{
                this.form.setFieldsValue(
                    {
                        name: data.name,
                        description: data.description,
                        start: data.start,
                        end:data.end
                    }
                );
            });
        }
    },
    props: {
        categoryid: String
    }
})
</script>
